class CurrencySelector {
  constructor() {
    this.currency = document.querySelector('.currency');
    this.radioButtons = this.currency.querySelectorAll('input[type="radio"]');
    this.currencyField = this.currency.querySelector('.currency__field');
    this.currencyLabel = this.currency.querySelectorAll('.currency__dropdown label');

    this.addSelectedClass = this.addSelectedClass.bind(this);
    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    this.handleCurrencyFieldClick = this.handleCurrencyFieldClick.bind(this);
    this.handleCurrencyLabelClick = this.handleCurrencyLabelClick.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  init() {
    this.addSelectedClass();
    this.addEventListeners();
  }

  addSelectedClass() {
    this.radioButtons.forEach((button) => {
      if (button.checked) {
        this.currency.className = 'currency';
        if (button.id === 'rub') {
          this.currency.classList.add('currency--rub');
        } else if (button.id === 'kzt') {
          this.currency.classList.add('currency--kzt');
        }
      }
    });
  }

  handleRadioButtonChange() {
    this.addSelectedClass();
  }

  handleCurrencyFieldClick() {
    this.currency.classList.toggle('dropdown-is-visible');
  }

  handleCurrencyLabelClick() {
    this.currency.classList.remove('dropdown-is-visible');
  }

  handleDocumentClick(e) {
    const click = e.composedPath().includes(this.currency);

    if (!click) {
      this.currency.classList.remove('dropdown-is-visible');
    }
  }

  addEventListeners() {
    this.radioButtons.forEach((button) => button.addEventListener('change', this.handleRadioButtonChange));
    this.currencyField.addEventListener('click', this.handleCurrencyFieldClick);
    this.currencyLabel.forEach((label) => label.addEventListener('click', this.handleCurrencyLabelClick));

    document.addEventListener('click', this.handleDocumentClick);
  }

  destroy() {
    this.radioButtons.forEach((button) => button.removeEventListener('change', this.handleRadioButtonChange));
    this.currencyField.removeEventListener('click', this.handleCurrencyFieldClick);
    this.currencyLabel.forEach((label) => label.removeEventListener('click', this.handleCurrencyLabelClick));

    document.removeEventListener('click', this.handleDocumentClick);
  }
}

export default CurrencySelector;
