const authDefault = {
  auth: document.querySelector('.auth'),
  authUp: document.querySelector('.auth-default__sign-up'),
  authIn: document.querySelector('.auth-default__sign-in'),

  toggleAuthClass(element, addClass, removeClass) {
    this.auth.classList.add(addClass);
    this.auth.classList.remove(removeClass);
    this.auth.querySelector(`.auth-default__${element}`).classList.add('active');
    this.auth.querySelector(`.auth-default__${element === 'sign-in' ? 'sign-up' : 'sign-in'}`).classList.remove('active');
  },

  init() {
    this.authUp.addEventListener('click', () => this.toggleAuthClass('sign-up', 'auth--sign-up', 'auth--sign-in'));
    this.authIn.addEventListener('click', () => this.toggleAuthClass('sign-in', 'auth--sign-in', 'auth--sign-up'));
  },
};

export default authDefault;
