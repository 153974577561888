import TextFields from 'text-fields';
import CurrencySelector from './CurrencySelector';
import authDefault from './authDefault';
import promotionCode from './promotionCode';

const currencySelector = new CurrencySelector();

TextFields.init().then();
currencySelector.init();
authDefault.init();
promotionCode('.promo-code', 't3');
