const promotionCode = (selector, field) => {
  const concealer = document.querySelector(selector);
  const input = document.getElementById(field);

  concealer.addEventListener('click', () => {
    concealer.remove();
    input.focus();
  });
};

export default promotionCode;
